{
  "settinsShipping": {
    "labels": {
      "addresses": "Addresses",
      "eori": "EORI number",
      "labelSize": "Preferred label size",
      "customBoxes": "Custom boxes"
    },
    "eori": {
      "buttonSave": "Save"
    },
    "preferedLabelSize": {
      "description": "This size will be the default size for your printer. If you can't find your preferred label size please contact us."
    },
    "customBoxes": {
      "buttonAdd": "Add",
      "labels": {
        "type": "Type",
        "customBoxName": "Custom box name",
        "height": "Height",
        "width": "Width",
        "length": "Length",
        "weight": "Weight",
        "default": "Default"
      },
      "actions": {
        "add": "Add",
        "delete": "Delete"
      }
    },
    "addresses": {
      "defaultPickupAddress": "Default pickup address",
      "defaultReturnAddress": "Default return address",
      "actions": {
        "edit": "Edit",
        "delete": "Delete",
        "add": "Add address"
      }
    },
    "platform": {
      "ACTIVE": "Connected",
      "BROKEN": "Broken",
      "reconnectToken": "Reconnect token",
      "addToken": "Add toekn"
    }
  },
  "managedShipping": {
    "label": "managedShipping",
    "intro": {
      "labels": {
        "workDescription": "How it works?",
        "preferredCouriers": "What if I want to send international orders with my preferred couriers?"
      },
      "header": {
        "subLine": "powered by Webinterpret",
        "description": "Enable managedShipping option on your listings to reach more customers abroad. Webinterpret will apply the most optimal international shipping service to all eligible items (including those previously without international shipping)."
      },
      "workDescription": {
        "box1": "Webinterpret checks the item's eligibility for international listing",
        "box2": "Webinterpret's algorithm is choosing the most optimal shipping service",
        "box3": "Webinterpret applies the service to your international listing",
        "box4": "You increase your international sales by offering hassle-free shipping"
      },
      "preferredCouriers": {
        "description": "You can send your orders with your preferred couriers.",
        "note": "Note: If the item has been listed and sold as part of the managedShipping Program, a fee will be charged in accordance with the program’s Terms & Conditions.",
        "linkLabel": "Learn more"
      },
      "start": "Start now"
    },
    "firstSetup": {
      "label": "Start the Managed Shipping Program",
      "description": "Enable Webinterpret shipping options on your international listings in order to increase your sales!",
      "start": "Start now"
    },
    "shippingSetup": {
      "options": {
        "label": "Please choose the shipping options for your international listings:",
        "option1": "Use {shippingLabel} exclusively",
        "option2": "Add {shippingLabel} options alongside your current shipping options",
        "option3": "Replace your current shipping options when {shippingLabel} rates are better"
      },
      "handlingFee": {
        "label": "Handling fee",
        "checkboxLabel": "Handling fee to be added on top of Webinterpret rates"
      },
      "parcel": {
        "label": "Parcel size"
      },
      "advancedCourier": {
        "label": "Advanced courier settings",
        "tooltip": ""
      }
    },
    "dashboard": {
      "description": "Webinterpret shipping options increase your international sales! We calculate optimal shipping rates for your international listings so that you spend more time selling and less time calculating. When enabling the Managed Shipping Program, Webinterpret shipping options are added to all items that can be listed internationally.",
      "info": "If you want to cancel the managed shipping program please contact our Customer Care. If disabling, Webinterpret shipping options will be removed from your international listings",
      "status": "Status",
      "coverage": "Coverage",
      "active": "Active",
      "inactive": "Inactive"
    },
    "footer": "Adding Webinterpret shipping options can take up to 24 hours, depending on the number of items in your inventory."
  }
}
