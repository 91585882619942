import loginPage from "@/i18n/en-GB/login-page-en-GB.json";
import dashboard from "@/i18n/en-GB/dashboard-en-GB.json";
import countriesIso from "@/i18n/en-GB/countries-en-GB.json";
import footer from "@/i18n/en-GB/footer-en-GB.json";
import returnPage from "@/i18n/en-GB/returns-page-en-GB.json";
import returns from "@/i18n/en-GB/returns-en-GB.json";
import general from "@/i18n/en-GB/general-en-GB.json";
import settingsPage from "@/i18n/en-GB/settings-page-en-GB.json";
import settingsReturns from "@/i18n/en-GB/settings-returns-en-GB.json";
import settingShipping from "@/i18n/en-GB/settings-shipping-en-GB.json";
import settingUser from "@/i18n/en-GB/settings-user-en-GB.json";
import shipmentsPage from "@/i18n/en-GB/shipments-page-en-GB.json";
import shipmentsOnboardingPage from "@/i18n/en-GB/shipments-onboarding-en-GB.json";
import errors from "@/i18n/en-GB/errors-en-US.json";
import ordersPage from "@/i18n/en-GB/orders-page-en-GB.json";
import inventoryPage from "@/i18n/en-GB/inventory-page-en-GB.json";
import components from "@/i18n/en-GB/components-en-GB.json";
import settlementPage from "@/i18n/en-GB/settlement-page-en-GB.json";
import errorPage from "@/i18n/en-GB/error-page-en-GB.json";
import analyticsPage from "@/i18n/en-GB/analytics-page-en-GB.json";
import invoicesPage from "@/i18n/en-GB/invoices-page-en-GB.json";
import salesInvoicesPage from "@/i18n/en-GB/sales-invoices-page-en-GB.json";

export default Object.assign(
  loginPage,
  dashboard,
  countriesIso,
  footer,
  returnPage,
  returns,
  general,
  settingsPage,
  settingsReturns,
  settingShipping,
  settingUser,
  shipmentsPage,
  errors,
  shipmentsOnboardingPage,
  ordersPage,
  inventoryPage,
  components,
  settlementPage,
  errorPage,
  analyticsPage,
  invoicesPage,
  salesInvoicesPage
);
