import { defineNuxtPlugin } from "#app";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Chart from "primevue/chart";
import Toolbar from "primevue/toolbar";
import MultiSelect from "primevue/multiselect";
import Message from "primevue/message";
import Carousel from "primevue/carousel";
import Tag from "primevue/tag";
import Avatar from "primevue/avatar";
import Card from "primevue/card";
import ProgressBar from "primevue/progressbar";
import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";
import Paginator from "primevue/paginator";
import TabMenu from "primevue/tabmenu";
import InputSwitch from "primevue/inputswitch";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Checkbox from "primevue/checkbox";
import RadioButton from "primevue/radiobutton";
import Skeleton from "primevue/skeleton";
import Knob from "primevue/knob";
import Divider from "primevue/divider";
import Chip from "primevue/chip";
import Menu from "primevue/menu";
import Calendar from "../primevue/Calendar.vue";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(PrimeVue, { ripple: true });
  nuxtApp.vueApp.use(ToastService);
  nuxtApp.vueApp.component("InputText", InputText);
  nuxtApp.vueApp.component("InputNumber", InputNumber);
  nuxtApp.vueApp.component("Button", Button);
  nuxtApp.vueApp.component("DataTable", DataTable);
  nuxtApp.vueApp.component("Column", Column);
  nuxtApp.vueApp.component("Chart", Chart);
  nuxtApp.vueApp.component("Toolbar", Toolbar);
  nuxtApp.vueApp.component("Calendar", Calendar);
  nuxtApp.vueApp.component("MultiSelect", MultiSelect);
  nuxtApp.vueApp.component("Message", Message);
  nuxtApp.vueApp.component("Carousel", Carousel);
  nuxtApp.vueApp.component("Tag", Tag);
  nuxtApp.vueApp.component("Avatar", Avatar);
  nuxtApp.vueApp.component("Card", Card);
  nuxtApp.vueApp.component("ProgressBar", ProgressBar);
  nuxtApp.vueApp.component("Dropdown", Dropdown);
  nuxtApp.vueApp.component("SelectButton", SelectButton);
  nuxtApp.vueApp.component("Paginator", Paginator);
  nuxtApp.vueApp.component("TabMenu", TabMenu);
  nuxtApp.vueApp.component("InputSwitch", InputSwitch);
  nuxtApp.vueApp.component("Toast", Toast);
  nuxtApp.vueApp.component("Checkbox", Checkbox);
  nuxtApp.vueApp.component("RadioButton", RadioButton);
  nuxtApp.vueApp.component("Skeleton", Skeleton);
  nuxtApp.vueApp.component("Knob", Knob);
  nuxtApp.vueApp.component("Divider", Divider);
  nuxtApp.vueApp.component("Chip", Chip);
  nuxtApp.vueApp.component("Menu", Menu);
});
