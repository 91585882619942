{
  "dashboard": {
    "title": "Dashboard",
    "welcomeMessage": "Hi, {name}",
    "labels": {
      "gmvTotal": "Total GMV",
      "totalItems": "Total Items",
      "totalChannels": "Total Countries",
      "gmv": "GMV",
      "markets": "Markets",
      "countries": "Countries",
      "returns": "Returns",
      "ads": "Ads",
      "uplift": "Uplift",
      "aov": "AOV",
      "orders": "Orders",
      "units": "Units",
      "bestPerformingItems": "Best Performing Items",
      "worstPerformingItems": "Worst Performing Items",
      "yourPerformance": "Your performance",
      "lastUpdate": "Last update: {date}"
    },
    "links": {
      "marketing": "Go to marketing"
    },
    "filters": {
      "platforms": "Platforms",
      "markets": "Markets",
      "dateRange": "Date range",
      "countries": "Countries",
      "ebay": "eBay",
      "amazon": "Amazon",
      "MoR": "Brand Expansion"
    },
    "returns": {
      "perCountry": "Return per country",
      "labels": {
        "wiReturns": "Webinterpret Returns",
        "totalReturns": "Total returns"
      },
      "tooltips": {
        "wiReturns": "The total number of returns processed by the Webinterpret Returns service.",
        "totalReturns": "The total number of returns processed by all services."
      }
    },
    "tooltips": {
      "gmvTotal": "Total value of items sold through Webinterpret services.",
      "totalItems": "The current number of items listed by Webinterpret on international sites on the selected Countries. Note: The Date Range does not apply to this number. ",
      "totalChannels": "The current number of international sites managed by Webinterpret on the selected Countries. Note: The Date Range does not apply to this number.",
      "countries": "Countries with the highest sales value generated through Webinterpret services.",
      "returns": "Webinterpret's automated free returns service for international and domestic listings encourages your audience to make purchases, builds brand trust, incentivizes further purchases and controls your return costs",
      "aov": "Average sales value generated per order through Webinterpret services.",
      "orders": "Number of orders generated through Webinterpret services.",
      "units": "Number of item units sold through Webinterpret services.",
      "bestPerformingItems": "Items with the highest sales value generated through Webinterpret services.",
      "worstPerformingItems": ""
    },
    "markets": {
      "showAll": "Show all"
    },
    "items": {
      "headers": {
        "product": "Product",
        "noOrders": "No. of orders",
        "noUnits": "Units",
        "gmv": "GMV"
      }
    },
    "charts": {
      "labels": {
        "GMV": "GMV",
        "orders": "Orders",
        "units": "Units",
        "details": "Details",
        "volatility": "Range Min-Max",
        "unitsReturned": "Units Returned",
        "returnRate": "Return rate",
        "gmvReturned": "GMV Returned"
      }
    }
  }
}
