{
  "analyticsPage": {
    "header": "Profit & Loss",
    "title": "Profit & Loss",
    "chartTitle": "Monthly performance",
    "table": {
      "headers": {
        "sales": "Sales",
        "soldGmv": "Sold GMV",
        "settledSoldGmv": "Sales",
        "returnRate": "% Returns",
        "netGmv": "Net GMV",
        "settledNetGmv": "Net Sales",
        "settled": "% Settled so far",
        "settledSales": "Settled Sales",
        "returnedGmv": "Returned GMV",
        "settledReturnedGmv": "Returns",
        "sellingCost": "Selling Costs",
        "sellingCostsPer": "% Selling Costs",
        "revenue": "Profit Contribution",
        "revenuePer": "% Profit Contribution",
        "sellingCostDetails": "Selling Cost Details",
        "lineHaul": "Line Haul",
        "inventoryHandling": "Inventory Handling",
        "importDuty": "Import Duty",
        "internationalLogisticsCosts": "International Logistics Costs",
        "marketplaceComission": "Marketplace Commission",
        "orderFulfillment": "Order Fulfillment",
        "returnCosts": "Return Costs",
        "marketplaceCosts": "Marketplace Costs",
        "advertisingCosts": "Advertising Costs",
        "transactions": "Transactions During The Month",
        "settledTransactions": "Settled Transactions",
        "performanceIndicators": "Performance indicators",
        "salesDuringTheMonth": "Sales during the month"
      }
    },
    "filters": {
      "dateRange": "Date",
      "showTrends": "Show trend",
      "showSellingCosts": "Show % of Selling Costs",
      "currency": "Currency"
    },
    "infoBox": {
      "header": "WHAT IS PROFIT & LOSS?",
      "content": "Track your business's financial performance effortlessly with our Profit and Loss (P&L) statement tool. It summarizes your revenues, costs, and expenses for a specific period, giving you a clear picture of your profitability. Simplify your financial management and make informed decisions with ease."
    },
    "tooltips": {
      "settledNetGmv": "Sales minus Returns",
      "sellingCost": "International Logistics Costs plus Marketplace Costs",
      "revenue": "Net Sales minus Selling Costs",
      "sellingCostsPer": "Selling Costs as ratio of Net Sales",
      "revenuePer": "Profit Contribution as ratio of Net Sales",
      "returnRate": "Returns as ratio of Sales"
    }
  }
}
