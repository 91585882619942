import { defineNuxtPlugin } from "#app";
import * as Sentry from "@sentry/vue";

function getSentryIntegrations() {
  const router = useRouter();
  const browserTracing = new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  });

  return [browserTracing];
}

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const environment = runtimeConfig.public.env;
  const vueApp = nuxtApp.vueApp;

  if (["production", "testenv"].includes(environment)) {
    Sentry.init({
      app: vueApp,
      dsn: "https://c6ef11f9d12eec58bc3180e480b60ee9@o17363.ingest.us.sentry.io/4506944956137472",
      environment,
      integrations: getSentryIntegrations(),
      sampleRate: 1,
      tracesSampleRate: 1,
    });
  }
});
