import * as Sentry from "@sentry/browser";
export default defineNuxtPlugin(async () => {
  const router = useRouter();
  const { setUser, getUser } = useUser();
  try {
    const data = await getUser();
    if (!("multichannel" in data.data.platforms)) {
      await Sentry.captureMessage(`${data.data.id} user redirected`, "info");
      //   window.location.replace("https://app.webinterpret.com/");
    }

    setUser((data as any).data);
  } catch (e) {
    console.error(e);
    return router.push("/login");
  }
});
