{
  "filters": { "rowsPerPage": "Show {e} per page" },
  "tables": {
    "showAll": "Show all",
    "hideAll": "Hide all"
  },
  "errors": {
    "label": "Error",
    "NO_DATA_PROVIDED": "No data provided",
    "CANNOT_GET_ADDRESS": "Cannot get address",
    "CANNOT_GET_RATES_WITHOUT_CATEGORIES": "Cannot get rates without categories",
    "ORIGIN_COUNTRY_NOT_SUPPORTED": "Origin country not supported",
    "CANNOT_DISPATCH_ORDER_ALREADY_DISPATCHED": "Cannot dispatch order already dispatched",
    "SHIPMENT_FOR_ORDER_ALREADY_CREATED": "Shipment for order already created",
    "MISSING_SHIPMENT_CORRESPONDING_WITH_ORDER": "Missing shipment corresponding with order",
    "CREATE_SHIPMENT": "Problem while creating shipment",
    "CANNOT_SAVE_ADDRESS": "Cannot save address",
    "RATES_EMPTY": "Sorry, we couldn't find any shipping solutions based on the information provided.",
    "GET_ORDERS_ERROR": "Something went wrong. Please try again later",
    "CANNOT_DELETE_DEFAULT_FOR_DROPOFF_ADDRESS": "Sorry, we cannot delete default dropoff address. Contact us to delete it",
    "CANNOT_DELETE_DEFAULT_FOR_PICKUP_ADDRESS": "Sorry, we cannot delete default pickup address. Contact us to delete it",
    "NO_DOCUMENTS_FOUND": "No documents found",
    "CANNOT_MERGE_DOCUMENTS": "Merging documents from bulk print labels resulted in an error regarding most probably S3 bucket. Try again",
    "ONBOARDING_DEFAULT_PICKUP_ADDRESS_EXISTS": "Pickup address already exists, go to the settings tab and verify it.",
    "ONBOARDING_DEFAULT_RETURN_ADDRESS_EXISTS": "Return address already exists, go to the settings tab and verify it.",
    "ONBOARDING_DEFAULT_DROPOFF_ADDRESS_EXISTS": "Dropoff address already exists, go to the settings tab and verify it",
    "INSURANCE_COVERAGE_LIMIT_EXCEEDED": "The maximum insurance coverage has been exceeded for this shipment",
    "UNSUPPORTED_COUNTRY_BY_INSURANCE_POLICY": "Destination country is not supported by our insurance policy",
    "SOMETHING_ERROR": "Something went wrong. Please try again later.",
    "NO_DEFAULT_ADDRESS": "Please set default address",
    "ALL_PACK_DIMENSIONS_MUST_BE_POSITIVE_VALUE": "Please set parcel dimensions",
    "NOT_SUPPORTED_FILE_FORMAT": "Not supported orders format",
    "WRONG_FILE_TYPE": "Wrong file type",
    "NO_VALID_SHIPMENTS_FOR_MANIFEST_FOUND": "No valid shipments for manifest found",
    "WRONG_VALUE": "Wrong value"
  },
  "successHeader": "Success",
  "successMessage": "Change saved",
  "dataDelay": "Data are refreshed every",
  "minutes": "{n} minute | {n} minutes",
  "hours": "{n} hour | {n} hours",
  "hideMenu": "Hide menu",
  "gtmInfo": "Filtering by date uses GMT time.",
  "gtmInfoLocal": "Local computer time is displayed on the page.",
  "paidOrdersInfo": "Only paid orders are shown.",
  "priceConversionInfo": "Prices were converted using the exchange rate of the day of the order."
}
