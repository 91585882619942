{
  "dashboard": {
    "welcomeMessage": "Salut, {name}",
    "labels": {
      "gmvTotal": "Total GMV",
      "totalItems": "Total localisés d'articles",
      "totalChannels": "Total de canaux",
      "gmv": "GMV",
      "markets": "Marchés",
      "returns": "Retours",
      "ads": "Publicités"
    }
  }
}
