import * as Sentry from "@sentry/browser";
import { UserPreferences } from "../types";
import preferences from "./useUser/preferences";

export type UseUser = {
  setUser: (userData: User) => void;
  getUser: () => Promise<void>;
  setPreferances: (locale: string) => void;
  canUseShipping: boolean;
  canUseReturns: boolean;
  user: User;
  ebayData: {
    id: string;
  };
  addresses: any;
};

type User = {
  id: number | null;
  name: string;
  firstName: string;
  currency: string;
  isAdmin: boolean;
};

const user: User = reactive({
  id: null,
  name: "",
  firstName: "",
  currency: "",
  isAdmin: false,
});

const ebayData = reactive({
  id: null,
});

const shippingOnboardingCompleted = ref(false);

const entitlements = ref(null);

const userPreferences = reactive<UserPreferences>({
  dimensions: "cm",
  weight: "kg",
  defaultPickUpCountryCode: "GB",
  currency: "",
});

let addresses = reactive([]);

let parcelsSize = reactive([]);

export function useUser() {
  const runtimeConfig = useRuntimeConfig();

  const setUser = (userData: any) => {
    Object.assign(user, userData);

    if (userData?.adminUserId) {
      user.isAdmin = true;
    }
  };

  /**
   * Get user data
   */
  // SUI endpoint must be replaced in future
  const getUser = async () => {
    const { data, pending, error, refresh } = await useFetch(
      `${runtimeConfig.public.apiGwEndpoint}/v1/auth`,
      {
        credentials: "include",
        method: "GET",
      },
    );

    if (data.value?.data?.id) {
      Sentry.setUser({ id: data.value.data.id });
    }

    // useTrackEvent("user_login", {
    //   id: data.value.data.id,
    //   isAdmin: !!data.value.data.adminUserId,
    // });

    // try {
    //   const dataPromisses = await Promise.all([
    //     // getEbayData(data.value),
    //     getUserEntitlements(data.value.data.id),
    //     // getOnboardingCompleted(data.value.data.id),
    //   ]);
    //   // ebayData.id = dataPromisses[0].source_accounts[0]?.account_id;
    //   entitlements.value = dataPromisses[1];
    //   shippingOnboardingCompleted.value = !!dataPromisses[2].value;
    // } catch (error) {
    //   console.error(error);
    // }

    return data.value;
  };

  const getEbayData = async (userData: any) => {
    const { data, pending, error, refresh } = await useFetch(
      `${runtimeConfig.public.apiGwEndpoint}/v1/users/${userData.data.id}/listing_flows/ebay`,
      {
        credentials: "include",
        method: "GET",
      },
    );

    return data.value;
  };

  const getUserEntitlements = async (userId: string) => {
    const { data, pending, error, refresh } = await useFetch(
      `${runtimeConfig.public.apiGwEndpoint}/v2/user_entitlements/${userId}`,
      {
        credentials: "include",
        method: "GET",
      },
    );

    return data.value;
  };

  const getOnboardingCompleted = async (userId: number) => {
    const { data, pending, error, refresh } = await useFetch(
      `${runtimeConfig.public.apiGwEndpoint}/v1/users/${userId}/settings/wi-shipping-onboarding-status`,
      {
        credentials: "include",
        method: "GET",
      },
    );

    return data.value;
  };

  const canUseShipping = computed(() => {
    return entitlements.value.can_use_shipping ?? false;
  });

  const canUseReturns = computed(() => {
    return (
      (entitlements.value.can_onboard_to_returns_domestic ||
        entitlements.value.can_onboard_to_returns_international_active) ??
      false
    );
  });

  const userDimensions = computed(() => {
    return userPreferences.dimensions;
  });

  const setPreferances = (locale: string) => {
    const userPreferencesData = preferences[locale.replaceAll("_", "-")];
    if (userPreferencesData) {
      userPreferences.dimensions = userPreferencesData.dimensions;
      userPreferences.weight = userPreferencesData.weight;
      userPreferences.defaultPickUpCountryCode =
        userPreferencesData.defaultPickUpCountryCode;
      userPreferences.currency = userPreferencesData.currency;
    }
  };

  const defaultPickUpAddress = computed(() => {
    const { defaultForPickup, defaultForReturn, id, ...rest } = addresses.find(
      (element) => element.defaultForPickup === true,
    );
    return rest;
  });

  const setUserAddresses = (addressesCollection: []) => {
    addresses = addressesCollection;
  };

  const setUserParcelsSize = (parcelsSizeCollection: []) => {
    parcelsSize = parcelsSizeCollection;
  };

  return {
    setUser,
    getUser,
    setPreferances,
    setUserAddresses,
    setUserParcelsSize,
    getOnboardingCompleted,
    user,
    parcelsSize,
    ebayData,
    canUseShipping,
    canUseReturns,
    userDimensions,
    addresses,
    defaultPickUpAddress,
    shippingOnboardingCompleted,
    isAdmin: computed(() => user.isAdmin),
  };
}
