{
  "settingsUser": {
    "address": {
      "labels": {
        "name": "Full name",
        "company": "Company",
        "address1": "Address 1",
        "address2": "Address 2",
        "postalCode": "Postal Code",
        "city": "City",
        "country": "Country",
        "state": "State"
      },
      "actions": {
        "cancel": "Cancel",
        "save": "Save address"
      }
    }
  }
}
