<template>
  <div class="container px-11.5 pt-8 mb-14 mx-auto h-full w-full flex">
    <div class="content-center m-auto">
      <ErrorsNoAccess v-if="error.message === 'SELLER_DOES_NOT_EXIST'" />
      <div v-else class="flex flex-col items-center">
        <img :src="`/img/error-500.svg`" alt="error" class="w-50 mb-12" />
        <h1
          class="text-center text-neutral-700 text-4xl font-normal mt-0 mb-3.5"
        >
          {{ $t("errorPage.header") }}
        </h1>
        <p
          class="mb-7 max-w-110 text-center text-neutral-700 text-sm font-normal leading-tight"
        >
          {{ $t("errorPage.info") }}
        </p>
        <Button :label="$t(`errorPage.refreshButton`)" @click="refreshPage" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps({
  error: Object as () => NuxtError,
});

const router = useRouter();
if (props.error?.statusCode === 404) {
  router.push("/");
}

const error = useError();

const refreshPage = () => {
  location.reload();
};
</script>
