export type UseAuth = {
  logIn: (login: string, password: string) => Promise<any>;
  logOut: () => Promise<any>;
};

export function useAuth(): UseAuth {
  const runtimeConfig = useRuntimeConfig();

  const logIn = async (username: string, password: string) => {
    const { data } = await useFetch(
      `${runtimeConfig.public.apiGwEndpoint}/v1/auth`,
      {
        credentials: "include",
        method: "POST",
        body: {
          username,
          password,
        },
      },
    );
    return data;
  };

  const logOut = () => {
    return useFetch(`${runtimeConfig.public.apiGwEndpoint}/v1/auth`, {
      credentials: "include",
      method: "DELETE",
    });
  };

  return {
    logIn,
    logOut,
  };
}
