<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script async setup>
import { DefaultApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/api/ApolloClient";
provide(DefaultApolloClient, apolloClient);
</script>
<style>
html {
  height: 100%;
  font-size: 14px;
  color: var(--text-color);
  font-family: var(--font-family);
  font-weight: 400;
}
body,
#__nuxt {
  min-height: 100%;
  margin: 0;
  height: 100%;
  width: 100%;
}
p {
  margin: 0;
}
a {
  outline: none;
  text-decoration: none;
  color: inherit;
}
.router-link-active {
  color: #2563eb;
}
</style>
