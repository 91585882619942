{
  "errorPage": {
    "header": "We’ll be back soon!",
    "headerNoAccess": "Access denied",
    "noAccess": "This service is not available to {h}",
    "info": "Sorry for the inconvenience. We're performing some maintenance at the moment. We will be back up shortly.",
    "refreshButton": "Refresh",
    "logoutButton": "Logout"
  }
}
