{
  "loginPage": {
    "header": "Connexion",
    "headerLabel": "Entrez vos coordonnées ci-dessous",
    "forgotPassword": "Mot de passe oublié ?",
    "form": {
      "email": "Adresse e-mail",
      "emailPlaceholder": "Entrez votre identifiant",
      "password": "Mot de passe",
      "passwordPlaceholder": "Entrez votre mot de passe"
    }
  }
}
