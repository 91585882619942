import { defineNuxtPlugin } from '#app/nuxt'
import { LazyCountryFlag, LazyDataBox, LazyDate, LazyItemName, LazyPrice, LazyRoundedNumber, LazySaleSite, LazySectionLabel, LazyTableDateField, LazyWiNumber } from '#components'
const lazyGlobalComponents = [
  ["CountryFlag", LazyCountryFlag],
["DataBox", LazyDataBox],
["Date", LazyDate],
["ItemName", LazyItemName],
["Price", LazyPrice],
["RoundedNumber", LazyRoundedNumber],
["SaleSite", LazySaleSite],
["SectionLabel", LazySectionLabel],
["TableDateField", LazyTableDateField],
["WiNumber", LazyWiNumber],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
