import { default as analyticsaTkPJpWh2yMeta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/analytics.vue?macro=true";
import { default as sales_45invoicesrgX1C2lui8Meta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/financials/sales-invoices.vue?macro=true";
import { default as indexpsHkXaiQlVMeta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/index.vue?macro=true";
import { default as inventoryOCdylrQvPMMeta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/inventory.vue?macro=true";
import { default as InvoicesdmD7fL8GRkMeta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/Invoices.vue?macro=true";
import { default as loginS08nsjXsHlMeta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/login.vue?macro=true";
import { default as ordersqFEB2bSr78Meta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/orders.vue?macro=true";
import { default as returnsm0CoM1EVhQMeta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/returns.vue?macro=true";
import { default as returns0etM1CoBzbMeta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/returns.vue?macro=true";
import { default as indexSMovV2FeLsMeta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/address/add/index.vue?macro=true";
import { default as _91id_93du1OWtRVLoMeta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/address/edit/[id].vue?macro=true";
import { default as indexuuYy7yCfF0Meta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/index.vue?macro=true";
import { default as integrationslmv0DjPh7GMeta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/integrations.vue?macro=true";
import { default as index1KLcz6Jxa5Meta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/sell-more-program/index.vue?macro=true";
import { default as shipments1nOlLwLQpvMeta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments.vue?macro=true";
import { default as settlementsfGbyYyJBA4Meta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settlements.vue?macro=true";
import { default as indexWvcsV7dJm2Meta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/shipments/index.vue?macro=true";
import { default as onboardingv0WDaJdq0JMeta } from "/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/shipments/onboarding.vue?macro=true";
export default [
  {
    name: analyticsaTkPJpWh2yMeta?.name ?? "analytics___en-GB",
    path: analyticsaTkPJpWh2yMeta?.path ?? "/analytics",
    meta: analyticsaTkPJpWh2yMeta || {},
    alias: analyticsaTkPJpWh2yMeta?.alias || [],
    redirect: analyticsaTkPJpWh2yMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/analytics.vue").then(m => m.default || m)
  },
  {
    name: analyticsaTkPJpWh2yMeta?.name ?? "analytics___fr-FR",
    path: analyticsaTkPJpWh2yMeta?.path ?? "/fr-FR/analytics",
    meta: analyticsaTkPJpWh2yMeta || {},
    alias: analyticsaTkPJpWh2yMeta?.alias || [],
    redirect: analyticsaTkPJpWh2yMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/analytics.vue").then(m => m.default || m)
  },
  {
    name: sales_45invoicesrgX1C2lui8Meta?.name ?? "financials-sales-invoices___en-GB",
    path: sales_45invoicesrgX1C2lui8Meta?.path ?? "/financials/sales-invoices",
    meta: sales_45invoicesrgX1C2lui8Meta || {},
    alias: sales_45invoicesrgX1C2lui8Meta?.alias || [],
    redirect: sales_45invoicesrgX1C2lui8Meta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/financials/sales-invoices.vue").then(m => m.default || m)
  },
  {
    name: sales_45invoicesrgX1C2lui8Meta?.name ?? "financials-sales-invoices___fr-FR",
    path: sales_45invoicesrgX1C2lui8Meta?.path ?? "/fr-FR/financials/sales-invoices",
    meta: sales_45invoicesrgX1C2lui8Meta || {},
    alias: sales_45invoicesrgX1C2lui8Meta?.alias || [],
    redirect: sales_45invoicesrgX1C2lui8Meta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/financials/sales-invoices.vue").then(m => m.default || m)
  },
  {
    name: indexpsHkXaiQlVMeta?.name ?? "index___en-GB",
    path: indexpsHkXaiQlVMeta?.path ?? "/",
    meta: indexpsHkXaiQlVMeta || {},
    alias: indexpsHkXaiQlVMeta?.alias || [],
    redirect: indexpsHkXaiQlVMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexpsHkXaiQlVMeta?.name ?? "index___fr-FR",
    path: indexpsHkXaiQlVMeta?.path ?? "/fr-FR",
    meta: indexpsHkXaiQlVMeta || {},
    alias: indexpsHkXaiQlVMeta?.alias || [],
    redirect: indexpsHkXaiQlVMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inventoryOCdylrQvPMMeta?.name ?? "inventory___en-GB",
    path: inventoryOCdylrQvPMMeta?.path ?? "/inventory",
    meta: inventoryOCdylrQvPMMeta || {},
    alias: inventoryOCdylrQvPMMeta?.alias || [],
    redirect: inventoryOCdylrQvPMMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/inventory.vue").then(m => m.default || m)
  },
  {
    name: inventoryOCdylrQvPMMeta?.name ?? "inventory___fr-FR",
    path: inventoryOCdylrQvPMMeta?.path ?? "/fr-FR/inventory",
    meta: inventoryOCdylrQvPMMeta || {},
    alias: inventoryOCdylrQvPMMeta?.alias || [],
    redirect: inventoryOCdylrQvPMMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/inventory.vue").then(m => m.default || m)
  },
  {
    name: InvoicesdmD7fL8GRkMeta?.name ?? "Invoices___en-GB",
    path: InvoicesdmD7fL8GRkMeta?.path ?? "/Invoices",
    meta: InvoicesdmD7fL8GRkMeta || {},
    alias: InvoicesdmD7fL8GRkMeta?.alias || [],
    redirect: InvoicesdmD7fL8GRkMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/Invoices.vue").then(m => m.default || m)
  },
  {
    name: InvoicesdmD7fL8GRkMeta?.name ?? "Invoices___fr-FR",
    path: InvoicesdmD7fL8GRkMeta?.path ?? "/fr-FR/Invoices",
    meta: InvoicesdmD7fL8GRkMeta || {},
    alias: InvoicesdmD7fL8GRkMeta?.alias || [],
    redirect: InvoicesdmD7fL8GRkMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/Invoices.vue").then(m => m.default || m)
  },
  {
    name: loginS08nsjXsHlMeta?.name ?? "login___en-GB",
    path: loginS08nsjXsHlMeta?.path ?? "/login",
    meta: loginS08nsjXsHlMeta || {},
    alias: loginS08nsjXsHlMeta?.alias || [],
    redirect: loginS08nsjXsHlMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginS08nsjXsHlMeta?.name ?? "login___fr-FR",
    path: loginS08nsjXsHlMeta?.path ?? "/fr-FR/login",
    meta: loginS08nsjXsHlMeta || {},
    alias: loginS08nsjXsHlMeta?.alias || [],
    redirect: loginS08nsjXsHlMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/login.vue").then(m => m.default || m)
  },
  {
    name: ordersqFEB2bSr78Meta?.name ?? "orders___en-GB",
    path: ordersqFEB2bSr78Meta?.path ?? "/orders",
    meta: ordersqFEB2bSr78Meta || {},
    alias: ordersqFEB2bSr78Meta?.alias || [],
    redirect: ordersqFEB2bSr78Meta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersqFEB2bSr78Meta?.name ?? "orders___fr-FR",
    path: ordersqFEB2bSr78Meta?.path ?? "/fr-FR/orders",
    meta: ordersqFEB2bSr78Meta || {},
    alias: ordersqFEB2bSr78Meta?.alias || [],
    redirect: ordersqFEB2bSr78Meta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: returnsm0CoM1EVhQMeta?.name ?? "returns___en-GB",
    path: returnsm0CoM1EVhQMeta?.path ?? "/returns",
    meta: returnsm0CoM1EVhQMeta || {},
    alias: returnsm0CoM1EVhQMeta?.alias || [],
    redirect: returnsm0CoM1EVhQMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/returns.vue").then(m => m.default || m)
  },
  {
    name: returnsm0CoM1EVhQMeta?.name ?? "returns___fr-FR",
    path: returnsm0CoM1EVhQMeta?.path ?? "/fr-FR/returns",
    meta: returnsm0CoM1EVhQMeta || {},
    alias: returnsm0CoM1EVhQMeta?.alias || [],
    redirect: returnsm0CoM1EVhQMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/returns.vue").then(m => m.default || m)
  },
  {
    name: returns0etM1CoBzbMeta?.name ?? "settings-returns___en-GB",
    path: returns0etM1CoBzbMeta?.path ?? "/settings/returns",
    meta: returns0etM1CoBzbMeta || {},
    alias: returns0etM1CoBzbMeta?.alias || [],
    redirect: returns0etM1CoBzbMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/returns.vue").then(m => m.default || m)
  },
  {
    name: returns0etM1CoBzbMeta?.name ?? "settings-returns___fr-FR",
    path: returns0etM1CoBzbMeta?.path ?? "/fr-FR/settings/returns",
    meta: returns0etM1CoBzbMeta || {},
    alias: returns0etM1CoBzbMeta?.alias || [],
    redirect: returns0etM1CoBzbMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/returns.vue").then(m => m.default || m)
  },
  {
    path: shipments1nOlLwLQpvMeta?.path ?? "/settings/shipments",
    children: [
  {
    name: indexSMovV2FeLsMeta?.name ?? "settings-shipments-address-add___en-GB",
    path: indexSMovV2FeLsMeta?.path ?? "address/add",
    meta: indexSMovV2FeLsMeta || {},
    alias: indexSMovV2FeLsMeta?.alias || [],
    redirect: indexSMovV2FeLsMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/address/add/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93du1OWtRVLoMeta?.name ?? "settings-shipments-address-edit-id___en-GB",
    path: _91id_93du1OWtRVLoMeta?.path ?? "address/edit/:id()",
    meta: _91id_93du1OWtRVLoMeta || {},
    alias: _91id_93du1OWtRVLoMeta?.alias || [],
    redirect: _91id_93du1OWtRVLoMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexuuYy7yCfF0Meta?.name ?? "settings-shipments___en-GB",
    path: indexuuYy7yCfF0Meta?.path ?? "",
    meta: indexuuYy7yCfF0Meta || {},
    alias: indexuuYy7yCfF0Meta?.alias || [],
    redirect: indexuuYy7yCfF0Meta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/index.vue").then(m => m.default || m)
  },
  {
    name: integrationslmv0DjPh7GMeta?.name ?? "settings-shipments-integrations___en-GB",
    path: integrationslmv0DjPh7GMeta?.path ?? "integrations",
    meta: integrationslmv0DjPh7GMeta || {},
    alias: integrationslmv0DjPh7GMeta?.alias || [],
    redirect: integrationslmv0DjPh7GMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/integrations.vue").then(m => m.default || m)
  },
  {
    name: index1KLcz6Jxa5Meta?.name ?? "settings-shipments-sell-more-program___en-GB",
    path: index1KLcz6Jxa5Meta?.path ?? "sell-more-program",
    meta: index1KLcz6Jxa5Meta || {},
    alias: index1KLcz6Jxa5Meta?.alias || [],
    redirect: index1KLcz6Jxa5Meta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/sell-more-program/index.vue").then(m => m.default || m)
  }
],
    name: shipments1nOlLwLQpvMeta?.name ?? undefined,
    meta: shipments1nOlLwLQpvMeta || {},
    alias: shipments1nOlLwLQpvMeta?.alias || [],
    redirect: shipments1nOlLwLQpvMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments.vue").then(m => m.default || m)
  },
  {
    path: shipments1nOlLwLQpvMeta?.path ?? "/fr-FR/settings/shipments",
    children: [
  {
    name: indexSMovV2FeLsMeta?.name ?? "settings-shipments-address-add___fr-FR",
    path: indexSMovV2FeLsMeta?.path ?? "address/add",
    meta: indexSMovV2FeLsMeta || {},
    alias: indexSMovV2FeLsMeta?.alias || [],
    redirect: indexSMovV2FeLsMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/address/add/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93du1OWtRVLoMeta?.name ?? "settings-shipments-address-edit-id___fr-FR",
    path: _91id_93du1OWtRVLoMeta?.path ?? "address/edit/:id()",
    meta: _91id_93du1OWtRVLoMeta || {},
    alias: _91id_93du1OWtRVLoMeta?.alias || [],
    redirect: _91id_93du1OWtRVLoMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexuuYy7yCfF0Meta?.name ?? "settings-shipments___fr-FR",
    path: indexuuYy7yCfF0Meta?.path ?? "",
    meta: indexuuYy7yCfF0Meta || {},
    alias: indexuuYy7yCfF0Meta?.alias || [],
    redirect: indexuuYy7yCfF0Meta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/index.vue").then(m => m.default || m)
  },
  {
    name: integrationslmv0DjPh7GMeta?.name ?? "settings-shipments-integrations___fr-FR",
    path: integrationslmv0DjPh7GMeta?.path ?? "integrations",
    meta: integrationslmv0DjPh7GMeta || {},
    alias: integrationslmv0DjPh7GMeta?.alias || [],
    redirect: integrationslmv0DjPh7GMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/integrations.vue").then(m => m.default || m)
  },
  {
    name: index1KLcz6Jxa5Meta?.name ?? "settings-shipments-sell-more-program___fr-FR",
    path: index1KLcz6Jxa5Meta?.path ?? "sell-more-program",
    meta: index1KLcz6Jxa5Meta || {},
    alias: index1KLcz6Jxa5Meta?.alias || [],
    redirect: index1KLcz6Jxa5Meta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments/sell-more-program/index.vue").then(m => m.default || m)
  }
],
    name: shipments1nOlLwLQpvMeta?.name ?? undefined,
    meta: shipments1nOlLwLQpvMeta || {},
    alias: shipments1nOlLwLQpvMeta?.alias || [],
    redirect: shipments1nOlLwLQpvMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settings/shipments.vue").then(m => m.default || m)
  },
  {
    name: settlementsfGbyYyJBA4Meta?.name ?? "settlements___en-GB",
    path: settlementsfGbyYyJBA4Meta?.path ?? "/settlements",
    meta: settlementsfGbyYyJBA4Meta || {},
    alias: settlementsfGbyYyJBA4Meta?.alias || [],
    redirect: settlementsfGbyYyJBA4Meta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settlements.vue").then(m => m.default || m)
  },
  {
    name: settlementsfGbyYyJBA4Meta?.name ?? "settlements___fr-FR",
    path: settlementsfGbyYyJBA4Meta?.path ?? "/fr-FR/settlements",
    meta: settlementsfGbyYyJBA4Meta || {},
    alias: settlementsfGbyYyJBA4Meta?.alias || [],
    redirect: settlementsfGbyYyJBA4Meta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/settlements.vue").then(m => m.default || m)
  },
  {
    name: indexWvcsV7dJm2Meta?.name ?? "shipments___en-GB",
    path: indexWvcsV7dJm2Meta?.path ?? "/shipments",
    meta: indexWvcsV7dJm2Meta || {},
    alias: indexWvcsV7dJm2Meta?.alias || [],
    redirect: indexWvcsV7dJm2Meta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/shipments/index.vue").then(m => m.default || m)
  },
  {
    name: indexWvcsV7dJm2Meta?.name ?? "shipments___fr-FR",
    path: indexWvcsV7dJm2Meta?.path ?? "/fr-FR/shipments",
    meta: indexWvcsV7dJm2Meta || {},
    alias: indexWvcsV7dJm2Meta?.alias || [],
    redirect: indexWvcsV7dJm2Meta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/shipments/index.vue").then(m => m.default || m)
  },
  {
    name: onboardingv0WDaJdq0JMeta?.name ?? "shipments-onboarding___en-GB",
    path: onboardingv0WDaJdq0JMeta?.path ?? "/shipments/onboarding",
    meta: onboardingv0WDaJdq0JMeta || {},
    alias: onboardingv0WDaJdq0JMeta?.alias || [],
    redirect: onboardingv0WDaJdq0JMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/shipments/onboarding.vue").then(m => m.default || m)
  },
  {
    name: onboardingv0WDaJdq0JMeta?.name ?? "shipments-onboarding___fr-FR",
    path: onboardingv0WDaJdq0JMeta?.path ?? "/fr-FR/shipments/onboarding",
    meta: onboardingv0WDaJdq0JMeta || {},
    alias: onboardingv0WDaJdq0JMeta?.alias || [],
    redirect: onboardingv0WDaJdq0JMeta?.redirect || undefined,
    component: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/pages/shipments/onboarding.vue").then(m => m.default || m)
  }
]