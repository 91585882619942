import validate from "/builds/chTxf3WX/0/main/wi-multichannel-ui/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/builds/chTxf3WX/0/main/wi-multichannel-ui/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/middleware/auth.ts"),
  "can-use-returns": () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/middleware/canUseReturns.ts"),
  "can-use-shipping": () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/middleware/canUseShipping.ts"),
  "is-onboarded": () => import("/builds/chTxf3WX/0/main/wi-multichannel-ui/middleware/isOnboarded.ts")
}