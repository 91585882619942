<template>
  <div class="flex flex-col items-center">
    <img
      :src="`/img/error-no-access.svg`"
      alt="error-no-access"
      class="w-39 mb-12"
    />
    <h1 class="text-center text-neutral-700 text-4xl font-normal mt-0 mb-3.5">
      {{ $t("errorPage.headerNoAccess") }}
    </h1>

    <i18n-t
      keypath="errorPage.noAccess"
      tag="p"
      class="mb-7 text-center text-neutral-700 text-sm font-normal leading-tight"
    >
      <template #h>
        <span class="font-bold">{{ user.name }} ({{ user.id }})</span>
      </template>
    </i18n-t>

    <Button :label="$t(`errorPage.logoutButton`)" @click="logOutHandler" />
  </div>
</template>
<script setup lang="ts">
const { logOut } = useAuth();
const { user } = useUser();
const logOutHandler = async () => {
  await logOut();
  window.location.replace("https://multichannel.webinterpret.com");
};
</script>
