{
  "returnsPage": {
    "header": "Returns",
    "empty": "No returns found",
    "filtersLabel": "Filters",
    "loading": "Loading returns",
    "filters": {
      "orderDateFrom": "Order date from",
      "orderDateTo": "Order date to",
      "buyerUsername": "Buyer username",
      "orderId": "Order id",
      "returnId": "Return id",
      "type": "Type",
      "statuses": "Status",
      "returnType": "Order type",
      "labels": "Labels",
      "destinationCountry": "Destination country"
    },
    "history": {
      "date": "Date",
      "status": "Status",
      "notes": "Notes",
      "addresses": "Return addresses"
    },
    "info": {
      "type": "Type",
      "orderDate": "Order date",
      "returnDate": "Return date",
      "returnLabel": "Return label",
      "trackingNumber": "Tracking number",
      "download": "Download"
    },
    "table": {
      "header": {
        "saleSite": "Sale site",
        "buyer": "Buyer",
        "type": "Type",
        "items": "Items(s)",
        "refund": "Refund",
        "returnId": "Return ID",
        "destination": "Destination country",
        "returnReason": "Return reason",
        "status": "Status"
      }
    },
    "returnType": {
      "DOMESTIC": "domestic",
      "INTERNATIONAL": "international"
    },
    "labels": {
      "WI_LABELS": "WI label"
    },
    "details": {
      "orderId": "Order ID",
      "returnId": "Return ID"
    },
    "ordersType": {
      "INTERNATIONAL": "International",
      "DOMESTIC": "Domestic",
      "managedShipping": "Managed shipping"
    }
  }
}
