{
  "returns": {
    "reasons": {
      "ARRIVED_DAMAGED": "Arrived damaged",
      "ARRIVED_LATE": "Arrived late",
      "BUYER_CANCEL_ORDER": "Buyer cancel order",
      "BUYER_NO_SHOW": "Buyer no show",
      "BUYER_NOT_SCHEDULED": "Buyer not scheduled",
      "BUYER_REFUSED_TO_PICKUP": "Buyer refused to pickup",
      "DEFECTIVE_ITEM": "Defective item",
      "DIFFERENT_FROM_LISTING": "Different from listing",
      "DOES_NOT_FIT": "No matching",
      "EXPIRED_ITEM": "Expired item",
      "FAKE_OR_COUNTERFEIT": "Fake or counterfeit",
      "FOUND_BETTER_PRICE": "Found better price",
      "IN_STORE_RETURN": "In store return",
      "MISSING_PARTS": "Missing parts",
      "NO_LONGER_NEED_ITEM": "No longer need item",
      "NO_REASON": "No reason",
      "NOT_AS_DESCRIBED": "Not as described",
      "ORDERED_ACCIDENTALLY": "Ordered accidentally",
      "ORDERED_DIFFERENT_ITEM": "Ordered different item",
      "ORDERED_WRONG_ITEM": "Ordered wrong item",
      "OTHER": "Other",
      "OUT_OF_STOCK": "Out of stock",
      "RETURNING_GIFT": "Returning gift",
      "VALET_DELIVERY_ISSUES": "Valet delivery issues",
      "VALET_UNAVAILABLE": "Valet unavailable",
      "WRONG_SIZE": "Wrong size"
    },
    "statuses": {
      "CLOSED": "Closed",
      "ESCALATED": "Escalated",
      "ITEM_DELIVERED": "Delivered",
      "ITEM_SHIPPED": "In transit",
      "LESS_THAN_A_FULL_REFUND_ISSUED": "Partial refund",
      "PARTIAL_REFUND_DECLINED": "Partial refund",
      "PARTIAL_REFUND_FAILED": "Partial refund",
      "PARTIAL_REFUND_INITIATED": "Partial refund",
      "PARTIAL_REFUND_REQUESTED": "Partial refund",
      "REFUND_INITIATED": "Refund",
      "READY_FOR_SHIPPING": "Ready to ship",
      "REPLACED": "Replaced",
      "REPLACEMENT_CLOSED": "Closed",
      "REPLACEMENT_DELIVERED": "Delivered",
      "REPLACEMENT_LABEL_REQUESTED": "Waiting for label",
      "REPLACEMENT_REQUESTED": "Return requested",
      "REPLACEMENT_SHIPPED": "In transit",
      "REPLACEMENT_STARTED": "Replacement started",
      "REPLACEMENT_WAITING_FOR_RMA": "Waiting for RMA",
      "RETURN_LABEL_REQUESTED": "Waiting for label",
      "RETURN_LABEL_PENDING": "Waiting for label",
      "RETURN_REJECTED": "Rejected",
      "RETURN_REQUESTED": "Return requested",
      "RETURN_REQUESTED_TIMEOUT": "Return requested",
      "RETURN_LABEL_PENDING_TIMEOUT": "Buyer waiting for label reminder",
      "RETURN_LABEL_REQUESTED_TIMEOUT": "Buyer requested label reminder",
      "UNKNOWN": "Unknown",
      "WAITING_FOR_RETURN_LABEL": "Waiting for label",
      "WAITING_FOR_RMA": "Waiting for RMA",
      "BUYER_NO_SHOW": "No Show",
      "DOES_NOT_FIT": "Not matching",
      "RMA_PENDING": "RMA pending",
      "ITEM_READY_TO_SHIP": "Item ready to ship"
    },
    "types": {
      "remorse buyer-paid": "buyer-paid",
      "remorse seller-paid": "free",
      "SNAD": "SNAD",
      "WI shipments": "WI returns",
      "WI return": "WI returns"
    }
  }
}
