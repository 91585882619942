{
  "inventoryPage": {
    "title": "Inventory",
    "header": "Inventory",
    "empty": "No products currently in warehouse or sold in the selected date range.",
    "filters": {
      "dateRange": "Order Date",
      "productIds": "ASIN, Product IDs",
      "markets": "Countries"
    },
    "table": {
      "headers": {
        "channel": "Channel",
        "markets": "Countries",
        "product": "Product",
        "inWarehouse": "In warehouse",
        "sold": "Sold",
        "salesPrice": "Sales Price",
        "expendAll": "Expand all",
        "collapseAll": "Collapse all"
      }
    },
    "tooltips": {
      "inWarehouse": "The current sellable stock stored in warehouses in the selected Countries.",
      "salesPrice": "The current selling price in the selected Countries.",
      "sold": "Quantity sold in the selected Countries and Date Range"
    }
  }
}
