import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink({
  uri: "https://api-multichannel-facade.webinterpret.com/graphql",
  // uri: "http://devshipping.webinterpret.com:8000/graphql",
  // uri: "https://api-multichannel-facade-testenv.devwebinterpret.com/graphql",
  credentials: "include",
});

// Cache implementation
const cache = new InMemoryCache({
  addTypename: false,
  resultCaching: false,
});

const graphqlOnError = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const sellerError = graphQLErrors.some((element) => {
      return element.message === "SELLER_DOES_NOT_EXIST";
    });

    if (sellerError) {
      throw showError({
        statusCode: 500,
        statusMessage: "SELLER_DOES_NOT_EXIST",
        fatal: true,
      });
    }
  }
});
// Create the apollo client
const apolloClient = new ApolloClient({
  link: graphqlOnError.concat(httpLink),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
  },
});

export default apolloClient;
