{
  "settinsReturns": {
    "labels": {
      "allowToReturn": "Allow to return",
      "freeReturns": "Free returns settings"
    },
    "infoBox": {
      "title": "What you need to know about returns",
      "content": "With free returns, your products stand a better chance of being chosen by buyers. We recommend a 60 days return period, because it brings the highest increase in sales, but you can also choose 30 or 14 days. If you prefer to not offer free returns on some eBay sites, your original return policies will apply",
      "linkLabel": "Learn more"
    },
    "itemCondition": {
      "itemCondition": "Item condition",
      "productsFree": "Products allowing free returns",
      "titleTooltip": "We recommend to offer free returns for all your products in order to maximize sales. In case you prefer not to do so for specific product conditions, such as used products, then buyer-paid returns will be offered instead.",
      "messages": {
        "success": "Change saved"
      }
    },
    "freeReturns": {
      "sites": "Sites",
      "offer": "Offer free returns",
      "labels": {
        "DAYS_60": "60 days",
        "DAYS_30": "30 days",
        "DAYS_15": "15 days",
        "DAYS_14": "14 days"
      },
      "domesticInfo": {
        "p1": "Your domestic market is not available in your current plan.",
        "p2": "To offer free return on your domestic market please upgrade.",
        "button": "Upgrade"
      },
      "freeReturnsSettingsTooltips": "These settings apply to all your products, except those that are not eligible for the Webinterpret Return Services, such as used products, very high value products, or dangerous goods.",
      "op": {
        "text": "original return policies apply",
        "tooltip": "If unchecked, your Webinterpret international listings will offer return policies identical to the international return policies offered on their corresponding domestic listings."
      }
    }
  }
}
