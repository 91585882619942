{
  "loginPage": {
    "title": "Sign in page",
    "header": "Sign in",
    "headerLabel": "Enter your details below",
    "forgotPassword": "Forgot your password?",
    "signInWith": "Sign in with",
    "detailsBelow": "or enter your details below",
    "form": {
      "email": "Email address",
      "emailPlaceholder": "Enter your login",
      "password": "Password",
      "passwordPlaceholder": "Enter your password"
    }
  }
}
