{
  "salesInvoicesPage": {
    "header": "Sales invoices",
    "title": "Sales invoices",
    "filters": {
      "dateRange": "Invoice Date",
      "leftToPay": "Show %"
    },
    "table": {
      "headers": {
        "invoiceDate": "Invoice Date",
        "invoiceNumber": "Invoice Number",
        "originalValue": "Original Value",
        "valueAdjustments": "Value Adjustments",
        "value": "Value",
        "payments": "Payments",
        "leftToPay": "Left to pay",
        "download": "Download"
      }
    }
  }
}
