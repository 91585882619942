import type { Locales } from "../../types";

const locales: Locales = {
  "en-US": {
    dimensions: "in",
    weight: "lb",
    defaultPickUpCountryCode: "US",
    currency: "$",
  },
  "en-GB": {
    dimensions: "cm",
    weight: "kg",
    defaultPickUpCountryCode: "GB",
    currency: "£",
  },
};

export default locales;
