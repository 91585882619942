{
  "settlementPage": {
    "title": "Settlements page",
    "header": "Settlements",
    "empty": "No items to display",
    "filters": {
      "dateRange": "Settlement Date"
    },
    "table": {
      "headers": {
        "expendAll": "Expand all",
        "collapseAll": "Collapse all",
        "date": "Date",
        "settled": "Settled",
        "soldGmv": " Sold GMV",
        "returnedGmv": "Returned GMV",
        "netGmv": "Net GMV",
        "sellingCosts": "Selling Costs",
        "adjustments": "Adjustments",
        "download": "Download",
        "total": "Total",
        "wiCosts": "Webinterpret Costs",
        "marketplaceCosts": "Marketplace Costs",
        "lineHaul": "Line Haul",
        "inventoryHandling": "Inventory Handling",
        "importDuty": "Import Duty",
        "marketplaceCommission": "Marketplace Commission",
        "orderFulfillment": "Order Fulfillment"
      }
    }
  }
}
