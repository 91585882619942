{
  "calendar": {
    "dropdown": {
      "today": "Today",
      "7days": "7 days",
      "14days": "14 days",
      "30days": "30 days",
      "custom": "Custom",
      "3months": "3 months",
      "6months": "6 months",
      "1year": "1 year"
    },
    "calendarLabel": "Choose date"
  }
}
