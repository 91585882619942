{
  "shipmentsPage": {
    "header": "Shipping",
    "table": {
      "header": {
        "saleSite": "Sale site",
        "buyer": "Buyer",
        "items": "Items",
        "orderDate": "Order date",
        "orderPrice": "Order price",
        "buyerCountry": "Buyer country",
        "shippingDetails": "Shipping details",
        "trackingLabel": "Tracking label"
      }
    },
    "filters": {
      "platforms": "Platforms",
      "ordersType": "Orders type",
      "carrier": "Carrier"
    },
    "platforms": {
      "EBAY": "eBay",
      "AMAZON": "Amazon",
      "CSV_FILE": "Imported orders",
      "SHOPIFY_FILE": "Shopify",
      "WOOCOMMERCE_FILE": "WooCommerce"
    },
    "ordersType": {
      "INTERNATIONAL": "International",
      "DOMESTIC": "Domestic",
      "managedShipping": "Managed shipping"
    },
    "orderDetails": {
      "labels": {
        "pickUpSlot": "Pick up slot",
        "deliveryService": "Delivery service",
        "shipFrom": "Ship from",
        "shipTo": "Ship to",
        "parcelSize": "Parcel size",
        "orderDetails": "Order details"
      },
      "itemId": "Item id",
      "insuranceCheckbox": "Insure my parcel",
      "summary": "Summary",
      "printLabel": "Print label",
      "dispatch": "Dispatch",
      "loadingDocuments": "Loading documents...",
      "documents": {
        "label": "Label",
        "commercial_invoice": "Commercial invoice",
        "packing_slip": "Packing slip",
        "p1": "Your shipment has been successfully created! You can download your documents",
        "print": "Print"
      }
    },
    "orderDetailsMs": {
      "msTitle": "International order placed as a part of the {ms} program*",
      "algorithm": "Our algorithm choose",
      "appxDimensions": "Approximate dimensions**",
      "workignDay": "working day",
      "insured": "insurance included",
      "totalCost": "Total cost",
      "p1": "* Fee for this order will be automatically added to your next invoice",
      "p2": "** Weight adjustment covered by us"
    },
    "couriers": {
      "table": {
        "headers": {
          "courier": "Courier",
          "delivery": "Delivery time",
          "price": "Price (ex. VAT)"
        }
      }
    }
  }
}
