{
  "invoicesPage": {
    "title": "Invoices",
    "header": "Invoices",
    "filters": {
      "dateRange": "Date",
      "invoiceNumber": "Invoice number"
    },
    "empty": "No items to display",
    "table": {
      "headers": {
        "date": "Date",
        "invoiceNumber": "Invoice number",
        "dueDate": "Due date",
        "status": "Status",
        "total": "Total"
      }
    },
    "files": {
      "transactions": "Transactions",
      "invoice": "Invoice"
    },
    "statuses": {
      "INVOICE_OVERDUE": "Invoice overdue",
      "PAID": "Paid"
    }
  }
}
