{
  "ordersPage": {
    "header": "Orders",
    "empty": "No order in the selected date range",
    "filters": {
      "orderId": "Order ID",
      "dateRange": "Order Date",
      "orderStatus": "Order Status",
      "markets": "Countries",
      "shippedTo": "Shipped To",
      "productIds": "ASIN, Product IDs",
      "statuses": {
        "PENDING": "Pending",
        "SHIPPED": "Shipped",
        "CANCELLED": "Cancelled"
      }
    },
    "table": {
      "headers": {
        "channel": "Channel",
        "market": "Country",
        "orderQty": "Order Qty.",
        "orderId": "Order Id",
        "orderTotal": "Order Total",
        "orderDate": "Order Date",
        "orderStatus": "Order Status",
        "shippedTo": "Shipped to",
        "orderedItems": "Ordered Item(s)",
        "qty": "Qty.",
        "itemPrice": "Item Price",
        "shipping": "Shipping",
        "giftWrap": "Gift Wrap",
        "expendAll": "Expand all",
        "collapseAll": "Collapse all"
      },
      "tooltips": {
        "tax": "Tax paid by the buyer",
        "item": "Item price minus promotional discount",
        "shipping": "Shipping cost minus shipping discount"
      }
    }
  }
}
