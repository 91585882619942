{
  "shipmentsOnboardingPage": {
    "header": "Shipping addresses",
    "labels": {
      "pickUpAddress": "Pick up address",
      "logistic": "Logistics department contact details"
    },
    "logisticForm": {
      "name": "Name",
      "phone": "Phone number",
      "email": "Email address"
    },
    "returnSameAsPickUp": "The same as pick up address"
  }
}
